// import { Modal } from 'bootstrap'
// import 'bootstrap';
// window.bootstrap = bootstrap;
// window.bootstrapModal = Modal;
// import "/node_modules/select2/src/scss/core";
// import Swal from 'sweetalert2' - вирус хохлов
// import './bootstrap';

// jQuery(function($){
//     // $.mask.autoclear = !1;
//     $("INPUT[type=tel]").mask("+7 (999) 999-99-99",{"placeholder":" "});
//     //$("#tin").mask("99-9999999");
//     //$("#ssn").mask("999-99-9999");
//     //$("#date").mask("99/99/9999",{placeholder:"mm/dd/yyyy"});
//  });

jQuery(document).ready(function(){
    // jQuery('[data-bs-toggle="tooltip"]').tooltip();
    // jQuery('[data-bs-toggle="popover"]').popover();
    AOS.init();
    const exp1 = new PhoneReplacer(jQuery('INPUT[type=tel]'), {
        masks: [
            ['+7 (DDD) DDD-DD-DD', ['+7', '7']],
            ['8 (DDD) DDD-DD-DD', ['8']],
            // ['+38 (DDD) DDD-DD-DD', ['+38', '38', '80']],
        ],
        masksDef: '+7 (DDD) DDD-DD-DD',
        placeholder: '_',
        addPlaceholder: true
    });

    jQuery.each(jQuery('.firstGold'), function(ind, val){
        var firstGold = jQuery(val);
        // console.log(firstGold.text());
        // console.log(firstGold.text().replace(/^([А-Яа-яA-Za-z\d\-]+)\ /i, '<span class="gold-text">$1</span> '));
        firstGold.html( firstGold.html().replace(/^([А-Яа-яA-Za-z\d\-]+)\ /i, '<span class="gold-text">$1</span> ') );
    });


    //------- SLIDE SLICE BEFORE END ---//
    // function beforeAfterSlide(comparison, cursorX, e){
    //     let slider = comparison.closest('.imgSlide');
    //     let before = slider.find('.imgSlideAfter');

    //     let widthBlock = slider.width();
    //     // let widthDocument = jQuery('BODY').width();
    //     // let position = comparison.position().left;
    //     let sliderOffset = slider.offset().left;
    //     // let arrowOffset = comparison.offset().left;
    //     let arrowOffsetX = cursorX - sliderOffset;

    //     // установим границы, чтобы ползунок не выходил
    //     if (arrowOffsetX < 0) arrowOffsetX = 0;
    //     if (arrowOffsetX > widthBlock) arrowOffsetX = widthBlock;

    //     let percent = Math.round(100 * arrowOffsetX / widthBlock);
    //     // console.log('percent '+percent);
    //     comparison.css("left", "calc("+percent+"% - 1.5px)");
    //     before.css("clip-path", 'polygon('+percent+'% 0, 100% 0, 100% 100%, '+percent+'% 100%)');
    //     // console.log('---------');
    // }
    // jQuery('.imgSlide .elementSlide')
    // .on("dragstart", () => false) // отмена drug&drop
    // .on("mousedown", function(e) {
    //     let comparison = jQuery(this);
    //     let slider = comparison.closest('.imgSlide');
    //     let before = slider.find('.imgSlideAfter');
    //     let doc = jQuery(document).on("mousemove", (e) => {
    //         let cursorX = e.pageX;
    //         beforeAfterSlide(comparison, cursorX, e);
    //     });
    //     doc.on("mouseup", () => doc.off("mousemove"));
    // });
    // // let listSliceSlider = document.getElementsByClassName('elementSlide');
    // jQuery('.imgSlide .elementSlide').each(function(){
    //     let slideTouch = false;
    //     this.addEventListener("touchstart", function(e) {
    //         slideTouch = true;
    //         // console.log('touchstart');
    //     });
    //     this.addEventListener("touchend", function(e) {
    //         slideTouch = false;
    //         // console.log('touchend');
    //     });
    //     this.addEventListener("touchmove", function(e) {
    //         if(slideTouch){
    //             let comparison = jQuery(this);
    //             let cursorX = e.changedTouches[0].pageX;
    //             beforeAfterSlide(comparison, cursorX, e)
    //         }
    //     });
    // })
    //---- END SLIDE SLICE BEFORE END ---//
  
    

    // scroll top page
    jQuery('#btnUpPageAll').on('click', function(){
        var thisObj = jQuery(this);
        //thisObj.removeClass('active');
        jQuery('html, body').animate({ scrollTop: jQuery('body').offset().top }, 1000);
    });
    // scroll get
    jQuery(window).on('scroll', function(){
        var thisObj = jQuery(this),
            topList = parseFloat(window.pageYOffset);
        if(topList < 1000){
            jQuery('#btnUpPageAll').hasClass('active') ? jQuery('#btnUpPageAll').removeClass('active') : false;
        } else {
            !jQuery('#btnUpPageAll').hasClass('active') ? jQuery('#btnUpPageAll').addClass('active') : false;
        }
    });
    
    
    jQuery('INPUT.numberInput').on('keypress', function(e){
        var val = jQuery(this).val(),
            list = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'];
        
        //console.log(String.fromCharCode(e.which));
        //console.log(list.indexOf(String.fromCharCode(e.which)));
        if(String.fromCharCode(e.which) == '.'){
            if(val.length <= 0){
                val = '0.';
                jQuery(this).val('0.');
            }
            if(val.indexOf('.') >= 0){
                return false;
            }
        }
        if(list.indexOf(String.fromCharCode(e.which)) < 0){
            return false;
        }
    });
    jQuery('INPUT.numberInputInt').on('keypress', function(e){
        var val = jQuery(this).val(),
            list = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
        
        if(list.indexOf(String.fromCharCode(e.which)) < 0){
            return false;
        }
    });
    jQuery('INPUT.numberInput, INPUT.numberInputInt').on('change', function(e){
        var numberText = jQuery(this).val();
        numberText = numberText.replace(/ /, '');
        numberText = parseFloat(numberText);
        if(isNaN(numberText)){
            numberText = 0;
        }
        jQuery(this).val(numberText);
        return false;
    });
    
    // INPUT LABEL ABSOLUTE
    // jQuery('.inputBlock.labelPlaceholder INPUT').on('focus', function(e){
    //     var thisObj = jQuery(this),
    //         inputBlock = thisObj.closest('.inputBlock');
    //     inputBlock.addClass('active');
    //     //return false;
    // }).on('blur', function(e){
    //     var thisObj = jQuery(this),
    //         inputBlock = thisObj.closest('.inputBlock');
    //     if(thisObj.val().length > 0){
    //         inputBlock.addClass('hasData');
    //     } else if(thisObj.val().length == 0 && inputBlock.hasClass('hasData')){
    //         inputBlock.removeClass('hasData');
    //     }
    //     inputBlock.removeClass('active');
    //     //return false;
    // });
    
    // INPUT change ERROR
    jQuery('BODY').on('click', 'INPUT.error, TEXTAREA.error, SELECT.error, CHECKBOX.error', function(){
        var thisObj = jQuery(this);
        thisObj.removeClass('error');
    });
    
    /* RESIZE PAGE */
    jQuery(window).resize(function(){
        //var size = $(window).width();
        typeof jQuery('.btnOpenMenu.active') != "undefined" && jQuery('.btnOpenMenu.active').length > 0 ? getMobileMenu(jQuery('.btnOpenMenu.active')) : false;
        //console.log(size);
    });
    /* END RESIZE PAGE */
    

    jQuery('BODY').on('click', '.getListOpen', function(){
        let thisObj = jQuery(this),
            target = thisObj.data('target'),
            targetObj = typeof target != "undefined" ? jQuery(target) : "undefined";
        if(typeof targetObj != "undefined"){
            let hasOpen = targetObj.hasClass('open');
            if(hasOpen){
                // autoHeightAnimate(thisObj.closest('.blockDesign'), 1000).then(() => jQuery(target).removeClass('open'));
                thisObj.closest('.blockDesign').css({'height': ''});
                jQuery(target).removeClass('open');
            } else {
                autoHeightAnimate(thisObj.closest('.blockDesign'), 300);
                setTimeout(()=>jQuery(target).addClass('open'), 300);
            }
        }
    });
    
    // let wrapperHeaderNotFixed = jQuery('.wrapperHeader:not(.wrapperHeaderFixed)').outerHeight();
    // jQuery('.wrapperHeaderBacking').css({height: wrapperHeaderNotFixed+'px'});
    // function fixedMenu(top){
    //     let thisObj = jQuery('.wrapperHeader'),
    //         fixedMatch = 50;
    //     if(top > fixedMatch && !thisObj.hasClass('.wrapperHeaderFixed')){
    //         thisObj.addClass('wrapperHeaderFixed');
    //         jQuery('.wrapperHeaderBacking').addClass('active');
    //     } else if(top < fixedMatch || top < thisObj.outerHeight()){
    //         thisObj.removeClass('wrapperHeaderFixed');
    //         jQuery('.wrapperHeaderBacking').removeClass('active');
    //     }
    // }
    // fixedMenu(window.scrollY);
    /* windowHead */
    jQuery(window).on('scroll', function(e){
        // console.log(window.scrollY);
        // fixedMenu(window.scrollY);
        // getHeadClassTop();
        // imgScroll();
    });
    /* end windowHead */
    
    /* windowMenu */
    function getMobileMenu(obj){
        var thisObj = obj,
            btnActive = thisObj.hasClass('active'),
            target = thisObj.data('target'),
            targetBtns = thisObj.data('btns'),
            listBtns = jQuery('.'+targetBtns),
            objTarget = jQuery('#'+target);
        if(!btnActive){
            //jQuery('BODY').addClass('blow');
            jQuery('BODY').addClass('modal-open');
            listBtns.addClass('active');
            objTarget.addClass('active');
            objTarget.animate({"opacity": "1"}, 10)
                .find('.contentHeaderMobile').animate({"right": "0px"}, 300)
                .addClass('active');
        } else {
            //jQuery('BODY').removeClass('blow');
            jQuery('BODY').removeClass('modal-open');
            listBtns.removeClass('active');
            objTarget.find('.contentHeaderMobile').animate({"right": "-430px"}, 200);
            objTarget.animate({"opacity": "0"}, 200, function(){jQuery(this).removeClass('active')});
        }
    }
    jQuery('.btnOpenMenu').on('click', function(e){
        var thisObj = jQuery(this);
        getMobileMenu(thisObj)
        return false;
    });
    jQuery('.wrapperHeadMobile').on('click', function(e){
        if(e.target === this){
            var thisObj = jQuery(this).find('.btnOpenMenu');
            getMobileMenu(thisObj)
            return false;
        }
    });
    jQuery('.wrapperHeadMobile a.getModal').on('click', function(e){
        var thisObj = jQuery(this),
            thisObjBtn = jQuery(this).parents('.wrapperHeadMobile').find('.btnOpenMenu');
        getMobileMenu(thisObjBtn)
        //return false;
    });
    jQuery('.getBody.nav-link').on('click', function(e){
        var thisObj = jQuery(this),
            link = thisObj.attr('href'),
            //target = link.replace(/^\/\?\#/, ''),
            target = thisObj.data('target'),
            blockTarget = typeof target != "undefined" ? jQuery('#'+target) : false;
        jQuery('.btnOpenMenu').click();
        //console.log(target);
        //console.log(typeof blockTarget != "undefined" && blockTarget != false && blockTarget.length > 0);
        if(typeof blockTarget != "undefined" && blockTarget != false && blockTarget.length > 0){
            jQuery('html, body').animate({ scrollTop: (blockTarget.offset().top - 100) }, 1000);
            history.pushState(null, null, link);
            //return false;
        } else {
            window.location.href = link;
        }
        // easeOutCubic
        return false;
    });
    // child menu
    jQuery('.dropdown SPAN.nav-link.dropdown-toggle').on('click', function(e){
        var thisObj = jQuery(this),
            dropdown = thisObj.closest('.dropdown'),
            active = dropdown.hasClass('open');
        if(!active){
            jQuery('.nav .dropdown').removeClass('open');
            dropdown.addClass('open');
        } else {
            dropdown.removeClass('open');
        }
        return false;
    });
    jQuery('BODY').on('click', function(e){
        let activeMenu = jQuery(this).find('.dropdown.open');
        if(activeMenu.length && jQuery(e.target).closest('.dropdown.open').length == 0){
            activeMenu.removeClass('open');
        }
    });
    /* end windowMenu */
    jQuery('BODY').on('click', '.navQuiz .dropQuiz-toggle', function(){
        let thisObj = jQuery(this),
            item = thisObj.closest('.nav-item'),
            drop = thisObj.next('.dropQuiz-menu'),
            isOpen = item.hasClass('open');
        if(!isOpen){
            item.addClass('open');
            drop.addClass('open');
            // runAudio_slide()
        }
    });
    jQuery('BODY').on('click', '.navQuiz .nav-drop-close', function(){
        let thisObj = jQuery(this),
            block = thisObj.closest('.dropQuiz-menu'),
            item = thisObj.closest('.nav-item'),
            isOpen = item.hasClass('open');
        if(isOpen){
            block.removeClass('open');
            item.removeClass('open');
            // runAudio_slide();
        }
    });
    
    
    
    /* Function to animate height: auto */
    function autoHeightAnimate(element, time){
        var curHeight = element.height(), // Get Default Height
            autoHeight = element.css('height', 'auto').height(); // Get Auto Height
        element.height(curHeight); // Reset to Default Height
        element.stop().animate({ height: autoHeight }, time); // Animate to Auto Height
    }
    function autoWidthAnimate(element, time, newWidth = 'auto'){
        var curWidth = element.width(),
            autoWidth = newWidth === 'auto' ? element.css('width', newWidth).width() : newWidth;
        element.width(curWidth);
        element.stop().animate({ width: autoWidth }, time, ()=>{element.css('width', newWidth)});
    }

    // left menu
    jQuery('.autoWidthAnimate').on('mouseenter', function(){
        let thisObj = jQuery(this);
        autoWidthAnimate(thisObj, 200);
    }).on('mouseleave', function(){
        let thisObj = jQuery(this);
        autoWidthAnimate(thisObj, 200, '60px');
    })
    
    
    // run video
    jQuery('.runVideo').on('click', function(){
        var thisObj = jQuery(this),
            videoRun = thisObj.data('video'),
            frameData = thisObj.data('frame'),
            video = jQuery('#'+videoRun);
        jQuery('.runVideo .btnPlay').hide();
        jQuery('.runVideo .usVideo').show();
        jQuery(frameData)
            .load(function() {
                jQuery(this).prop('contentWindow').postMessage('{"event": "command", "func": "playVideo", "args": ""}', "*");
            })
            //.attr("frameborder", 0)
            //.attr("allowfullscreen", 1)
            .appendTo(video);
        //video.append(frameData);
        //thisObj.hide();
        //setTimeout(function(){video.find('FRAME')[0].contentWindow.postMessage('{"event": "command", "func": "playVideo", "args": ""}', "*")}, 2500);
    });
    

    // open modal
    var oldLabelModal = '', oldDataLabelModal = '';
    jQuery('.getModal').on('click', function(){
        var thisObj = jQuery(this),
            target = thisObj.data('bs-target'),
            modalBlock = jQuery(target),
            modalLabel = typeof modalBlock != "undefined" ? modalBlock.find('.modal-body .labelModal') : '',
            label = thisObj.data('label'),
            labelModal = thisObj.data('label-modal');
        // console.log(label);
        // console.log(modalBlock);
        // console.log(typeof modalBlock != "undefined");
        // console.log(modalBlock.find('.modal-body .h2').text());
        // console.log(labelModal);
        if(typeof labelModal != "undefined" && labelModal != ''){
            if(typeof modalLabel != "undefined" && modalLabel.text() != '' && modalLabel.length > 0){
                oldLabelModal = modalLabel.text();
                modalLabel.text(labelModal);
            }
        }
        if(typeof label != "undefined" && label != ''){
            oldDataLabelModal = modalBlock.find('FORM').data('label');
            modalBlock.find('FORM').data('label', label);
        }
    });
    jQuery('.modalWindow').on('hide.bs.modal', function(){
        var thisObj = jQuery(this),
            modalLabel = thisObj.find('.modal-body .labelModal');
        if(typeof modalLabel != "undefined" && oldLabelModal != ''){
            modalLabel.text(oldLabelModal);
        }
        if(typeof oldDataLabelModal != "undefined" && oldDataLabelModal != ''){
            thisObj.find('FORM').data('label', oldDataLabelModal);
        }
        // getAlert('success', 'Ну хорошо', 'Закрыли: '+modalLabel.text(), 'top-end', false);
    });
    
    jQuery('.openModal').on('click', function(e){
        let thisObj = jQuery(this),
            targetModal = thisObj.data('target-modal'),
            modal = jQuery(targetModal);
        e.preventDefault();
        if(typeof modal != "undefined"){
            modal.addClass('open');
        }
    });
    jQuery('.modal .closeModal').on('click', function(e){
        let thisObj = jQuery(this);
        thisObj.closest('.modal').removeClass('open');
        e.preventDefault();
    });
    
    
    window.getAlert = function (title, text){
        jQuery('#alertModal .labelModal').html(title);
        jQuery('#alertModal .hDesc').html(text);
        jQuery('#alertModal').addClass('open');
    }


    // TABS
    jQuery('.crg-tabs .tabs-item').on('click', function(e){
        let thisObj = jQuery(this),
            tabRow = thisObj.closest('.crg-tabs'),
            target = thisObj.data('target'),
            tabContentRow = jQuery(target).closest('.crg-tabs-content');
        e.preventDefault(); e.stopPropagation();
        tabRow.find('.tabs-item.active').removeClass('active');
        thisObj.addClass('active');
        tabContentRow.find('.crg-tabs-content-item.active').removeClass('active');
        jQuery(target).addClass('active');
    });

    // REF
    jQuery('.getLinkOtherSite').on('click', function(e){
        let thisObj = jQuery(this),
            link = thisObj.data('link');
        e.preventDefault(); e.stopPropagation();
        if(typeof link != "undefined"){
            if(!link.match(/^http.+/)) link = 'https://' + link;
            let linkHtml = document.createElement("a");
            linkHtml.target = '_nblank';
            linkHtml.href = link;
            linkHtml.click();
            linkHtml.remove();
        }
    });

    // ACCORDION
    jQuery('.crgAccordion').on('click', '.itemAccordion', function(e){
        let thisObj = jQuery(this);
        let activeItemObj = thisObj.closest('.crgAccordion').find('.itemAccordion.open');
        let bodyAcc = thisObj.find('.bodyAcc');
        let time = 200;
        // e.preventDefault(); e.stopPropagation();
        activeItemObj.removeClass('open').find('.bodyAcc').animate({ height: 0 }, time);
        // thisObj.addClass('open');
        let curHeight = bodyAcc.height(), // Get Default Height
            autoHeight = bodyAcc.css('height', 'auto').height(); // Get Auto Height
        bodyAcc.height(curHeight); // Reset to Default Height
        bodyAcc.animate({ height: autoHeight }, time, function(){ bodyAcc.css('height', 'auto'); thisObj.addClass('open') }); // Animate to Auto Height
    });


    jQuery('.tabs .tabItem').on('click', function(e){
        let thisObj = jQuery(this),
            tabRow = thisObj.closest('.tabs'),
            target = thisObj.data('target'),
            tabContentRow = jQuery(target).closest('.tabsContent');
            // swiperContainer = tabContentRow.find('.swiper-container'),
            // sw = swiperContainer.data('swiper');
        // e.preventDefault(); e.stopPropagation();
        tabRow.find('.tabItem.active').removeClass('active');
        thisObj.addClass('active');
        tabContentRow.find('.tabContentItem.active').removeClass('active');
        jQuery(target).addClass('active');
        // sw.update();
        // eval(sw).update();
        // return false;
    });

    jQuery('.wrapperCatalogPageHead .colImages .smallImages .img').on('click', function(e){
        let thisObj = jQuery(this),
            hasActive = thisObj.hasClass('.active'),
            closestBlock = thisObj.closest('.colImages'),
            bigImages = closestBlock.find('.bigImage'),
            target = thisObj.data('target')
        if(!hasActive && typeof target != "undefined" && typeof bigImages != "undefined"){
            let setImg = bigImages.find(target);
            if(typeof setImg != "undefined"){
                bigImages.find('.active').removeClass('active');
                closestBlock.find('.active').removeClass('active');
                setImg.addClass('active');
                thisObj.addClass('active');
            }
        }
    });

    
    // MODAL SEND
    jQuery('FORM[name=callMeGo]').submit(function(){
        var thisObj = jQuery(this),
            name = thisObj.find('INPUT[name=name]'),
            phone = thisObj.find('INPUT[name=phone]'),
            email = thisObj.find('INPUT[name=email]'),
            title = typeof jQuery('h1') != "undefined" ? jQuery('h1').text() : '',
            message = thisObj.find('TEXTAREA[name=message]'),
            url = thisObj.data('url'),
            iagree = thisObj.find('INPUT[name=politics]'),
            label = thisObj.data('label'),
            allOk = true,
            post = [];
        if(typeof name != "undefined"){
            if(name.val() == ''){
                getAlert('Ошибка', 'Не все поля заполнены!<br>>Введите свое имя!</br>Require name');
                name.addClass('error');
                name.focus();
                return false;
            } else {
                name = name.val();
            }
        } else {
            name = '';
        }
        if(typeof phone == "undefined" || typeof phone.val() == "undefined" || phone.val() == ''){
            phone = '';
        } else {
            if(phone.val() == ''){
                getAlert('Ошибка', 'Не все поля заполнены!<br>Введите свой телефон!');
                phone.focus();
                return false;
            } else {
                var r2 = /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/i,
                    r8 = /^8 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/i;
                    // r8 = /^\+7 \(8[0-9]{2}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/i;
                // console.log(phone.val());
                if(!r2.test(phone.val()) && !r8.test(phone.val())){
                    getAlert('Ошибка', 'Не все поля заполнены!<br>Не верно введен телефон!');
                    phone.addClass('error');
                    phone.focus();
                    return false;
                }
            }
            phone = phone.val();
        }
        if(typeof email == "undefined" || typeof email.val() == "undefined" || email.val() == ''){
            email = '';
        } else {
            email = email.val();
        }
        if(typeof message == "undefined" || typeof message.val() == "undefined" || message.val() == ''){
            message = '';
        } else {
            message = message.val();
        }
        if(typeof url == "undefined"){
            url = '';
        }
        if(typeof label == "undefined"){
            label = '';
        }
        //console.log(iagree);
        if(typeof iagree == "undefined" || typeof iagree.val() == "undefined" || iagree.val() == ''){
            iagree = '';
        } else {
            if(iagree.prop('checked') != true){
                getAlert('Ошибка', 'Согласие с пользовательским соглашением и политикой конфиденциальности обязательно!</br>Consent to the user agreement and privacy policy is required!');
                allOk = false;
                return false;
            }
        }
        
        if(allOk){
            post = {name:name, phone:phone, email:email, message:message, label:label, url:url, title:title};
            console.log(JSON.stringify(post));
            callMeGo(JSON.stringify(post), thisObj);
        }
        return false;
    });
    
    

    function callMeGo(post, thisObj){
        jQuery.ajax({
            url    : "/ajax/mail-to",
            type   : 'POST',
            //dataType : 'json',
            data : {data: post},
            headers: { 'X-CSRF-Token': thisObj.find('INPUT[name=_token]').val() },
            cache  : false,
            //success: function(data){}
        })
        .done( function(data){
                console.log(data);
                //data = JSON.parse(data);
                if( data.status ){
                    getAlert('<strong>Спасибо</strong> за заявку!', '');
                    if(typeof thisObj.closest('.modal') != "undefined") thisObj.closest('.modal').removeClass('open');
                } else {
                    getAlert('Ошибка на сервере', 'Повторите чуть позже <br>'+data.message);
                }
            }
        )
        .fail(function(data){
            console.log(data);
            getAlert('Ошибка на сервере', 'Повторите чуть позже <br>'+data.message);
            //alert('Error');
            }
        );
    }

});







